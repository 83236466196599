import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { BsCreditCard, BsTruck } from 'react-icons/bs';
import { AiOutlineSafety } from 'react-icons/ai';
import Container from '@/components/atoms/Container';
import { Text } from '@/components/atoms';
import Image from '@/components/atoms/Image';

import style from './preLanding.module.scss';

import MaxContainer from '@/components/atoms/MaxContainer';
import useDeviceType from '@/hooks/useDeviceType';
import { Slider } from '@/components/molecules';

import CyberBenefits from '@/components/molecules/CyberBenefits';
import CyberLink from '@/components/molecules/CyberLink';
import BrandsLogoSlider from '@/components/organisms/BrandsLogoSlider';
import brandsAPI from '@/api/brands';
import LandingCountdown from '@/components/molecules/LandingCountdown';
import Layout from '@/components/Layout';
import Newsletter from '@/components/organisms/Footer/Newsletter';

const PreLanding = () => {
  const { isDesktop } = useDeviceType();
  const storeId = useSelector((state) => state.store);
  const [brands, setBrands] = useState([]);
  const fetchData = async () => {
    const result = await brandsAPI.get(storeId);
    if (result.success) {
      setBrands(result.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const ribbonBenefits = [
    {
      id: '0',
      title: 'Compra en cuotas',
      text: 'Podés pagar con débito, MODO o hasta 9 cuotas sin interés con tarjeta bancarizadas',
      icon: <BsCreditCard size={50} />
    },
    {
      id: '1',
      title: 'Envíos gratis',
      text: 'Envíos gratis a partir de $10.000. Si es tu primer compra, tenés el primer cambio gratis.',
      icon: <BsTruck size={50} />
    },
    {
      id: '2',
      title: 'Compra protegida',
      text: 'Protegemos todos los datos y la identidad de nuestros clientes en todo momento.',
      icon: <AiOutlineSafety size={50} />
    }
  ];

  const data = [
    {
      id: 1,
      banner: isDesktop
        ? '/assets/cyber/shoe-desktop.webp'
        : '/assets/cyber/shoe-mobile.webp',
      title: 'CALZADO',
      text: '¡Durante Cyber Week no te pierdas la oportunidad de renovar tu calzado deportivo! Encontrarás una amplia selección de marcas reconocidas con increíbles descuentos. Aprovecha estas ofertas para conseguir tus zapatillas favoritas a precios irresistibles. ¡No dejes pasar esta oportunidad para equiparte con lo mejor!',
      link: '/calzado/p/1'
    },
    {
      id: 2,
      banner: isDesktop
        ? '/assets/cyber/kick-desktop.webp'
        : '/assets/cyber/kick-mobile.webp',
      title: 'INDUMENTARIA',
      text: '¡No te pierdas las increíbles ofertas en indumentaria deportiva! Encuentra ropa de entrenamiento de las mejores marcas. Ya sea para el gimnasio, correr o practicar tu deporte favorito, esta es la oportunidad perfecta para actualizar tu guardarropa deportivo con estilo y calidad. ¡Aprovecha y equípate al mejor precio!',
      link: '/indumentaria/p/1'
    },
    {
      id: 3,
      banner: isDesktop
        ? '/assets/cyber/tennis-desktop.webp'
        : '/assets/cyber/tennis-mobile.webp',
      title: 'ACCESORIOS',
      text: '¡Durante Cyber Week, completa tu look deportivo con los mejores accesorios! Encontrarás medias, gorras, guantes y más, de tus marcas favoritas, con descuentos imperdibles. Es la ocasión ideal para equiparte con todo lo necesario y llevar tu entrenamiento al siguiente nivel. ¡Aprovecha estas ofertas y no te quedes sin los tuyos!',
      link: '/accesorios/p/1'
    }
  ];

  const installments = [
    { id: 1, name: '3 sin interes', src: '/assets/cyber/3cuotas.webp' },
    { id: 2, name: '6 sin interes', src: '/assets/cyber/6cuotas.webp' },
    { id: 3, name: '9 sin interes', src: '/assets/cyber/9cuotas.webp' },
    { id: 4, name: '5 sin interes', src: '/assets/cyber/5cuotas.webp' }
  ];

  const newsletter = {
    background: '#282828',
    title: {
      text: '¡SÉ EL PRIMERO EN RECIBIR LAS MEJORES OFERTAS DEL CYBER MONDAY!',
      color: 'white'
    },
    caption: {
      text: 'Preparate para Cyber Monday 2024, ya podés registrarte y recibir las mejores ofertas de la fecha.',
      color: 'var(--color-primary)'
    },
    rightImage: '/assets/cyber/newsletter-right.webp',
    leftImage: '/assets/cyber/newsletter-left.webp',
    segments: [1, 6]
  };

  return (
    <Layout
      title="Cyber Monday"
      withoutLazyFooter
      withoutNewsletter
      description="Descubre nuestros valiosos partners y colaboradores. Trabajamos juntos para ofrecerte productos de calidad y una experiencia de compra excepcional. "
    >
      <MaxContainer>
        <div className={style.bannerContainer}>
          <div className={style.text}>
            <Text
              textColor="white"
              textStyle={{
                fontSize: `${isDesktop ? '32px' : '16px'}`,
                fontWeight: 700
              }}
            >
              YA LLEGA
            </Text>
            <Text
              textColor="var(--color-primary)"
              variant="h1"
              textStyle={{
                fontSize: `${isDesktop ? '64px' : '40px'}`,
                fontWeight: 700,
                textAlign: 'center',
                textShadow: '0px 13px 25px rgba(0,0,0,0.54)'
              }}
            >
              CYBER MONDAY
            </Text>
            <Text
              textColor="white"
              textStyle={{
                fontSize: `${isDesktop ? '24px' : '14px'}`,
                fontWeight: 700
              }}
            >
              DEL 04 AL 06 DE NOVIEMBRE
            </Text>
            <Text
              className={style.off}
              textColor="transparent"
              textStyle={{
                fontSize: `${isDesktop ? '36px' : '22px'}`,
                fontWeight: 700,
                textShadow: '0px 7px 15px rgba(0,0,0,0.84)',
                marginBottom: '15px'
              }}
            >
              HASTA 50% OFF
            </Text>
            <LandingCountdown />
          </div>
          <div className={style.img}>
            <Image
              width="100%"
              height="100%"
              src="/assets/cyber/cyberbanner-desktop.webp"
              alt="banner"
            />
          </div>
        </div>
        <div className={style.benefits}>
          {!isDesktop ? (
            <Slider
              withDots
              withoutChevrons
            >
              {ribbonBenefits.map((r) => (
                <CyberBenefits
                  title={r.title}
                  text={r.text}
                  key={r.id}
                  icon={r.icon}
                />
              ))}
            </Slider>
          ) : (
            <div className={style.desktop}>
              {ribbonBenefits.map((r) => (
                <CyberBenefits
                  title={r.title}
                  text={r.text}
                  key={r.id}
                  icon={r.icon}
                />
              ))}
            </div>
          )}
        </div>
        <Newsletter custom={newsletter} />
        <Container>
          <Text
            textColor="white"
            variant="h2"
            className={style.contentTitle}
          >
            ¡Encontrá las mejores ofertas y promociones del Cyber Monday 2024 en
            Vaypol!
          </Text>
          <div className={style.content}>
            {data.map((d, i) => (
              <CyberLink
                banner={d.banner}
                title={d.title}
                text={d.text}
                link={d.link}
                align={
                  isDesktop ? (i % 2 === 0 && 'right') || 'left' : 'center'
                }
              />
            ))}
          </div>
        </Container>
        <Text
          textColor="white"
          className={style.installmentsTitle}
        >
          ¡Conocé nuestras Promociones Bancarias!
        </Text>
        <div className={style.installments}>
          {!isDesktop ? (
            <Slider
              withDots
              withoutChevrons
            >
              {installments.map((i) => (
                <div
                  key={i.id}
                  className={style.installmentsSlide}
                >
                  <Image
                    width="auto%"
                    height="auto"
                    src={i.src}
                    alt={i.name}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <div className={style.desktop}>
              {installments.map((i) => (
                <Image
                  width="20%"
                  height="auto"
                  src={i.src}
                  alt={i.name}
                />
              ))}
            </div>
          )}
        </div>
        <div className={style.brands}>
          <Text
            textColor="white"
            className={style.brandsTitle}
          >
            Algunas de las Marcas que participan en el Cyber Monday 2024
          </Text>
          <BrandsLogoSlider
            items={brands}
            noLinks
          />
        </div>
      </MaxContainer>
    </Layout>
  );
};

export default PreLanding;
