/* eslint-disable no-nested-ternary */
import capitalize from '@/services/capitalize';

const renderGalleryInfo = ({
  initialReduxState,
  keywords,
  gender,
  landing,
  orderBy
}) => {
  const value = capitalize(`${gender || ''}`, '-');
  const productsSelected = initialReduxState.products?.filtersOptions;
  const findTypeOfProductSelected = (type) => {
    if (!productsSelected?.[type]) return '';
    return productsSelected[type].options
      .filter((o) => o.selected)
      .map((o) => o.name)
      .join(', ');
  };
  const business = findTypeOfProductSelected('business');
  const brand = findTypeOfProductSelected('brand');
  const kind = findTypeOfProductSelected('kind');
  const sport = findTypeOfProductSelected('sport');

  const allProductsTitle = () => {
    switch (orderBy) {
      case 'ofertas':
        return '¡Comprá nuestras Ofertas, Promociones y Descuentos!';
      case 'lanzamientos':
        return '¡Comprá nuestros Lanzamientos!';
      default:
        return `Listado de Productos${gender ? ` de ${gender}` : ''}`;
    }
  };
  const landingOrKeywordTitle = (withGender) => {
    if (withGender) {
      return `Comprá productos${
        keywords ? ` de ${capitalize(keywords)}` : ''
      } ${
        landing ? ` de ${capitalize(landing, '-').replace('ino', 'iño')}` : ''
      } para ${capitalize(gender)}`;
    }
    return `Comprá lo mejor de ${
      capitalize(landing, '-')?.replace('ino', 'iño') || capitalize(keywords)
    }`;
  };
  const isDifferentToKeyword = (option) => {
    return option && option.toLowerCase() !== keywords?.toLowerCase();
  };

  const renderDescription = () => {
    const option = kind || brand || business || sport;

    const isPlural = option[option.lengt - 1] === 's';
    const isMasculine = isPlural
      ? option[option.length - 2] === 'o' || option[option.length - 2] === 'e'
      : option[option.length - 1] === 'o' ||
        option[option.length - 1] === 'e' ||
        option[option.length - 2] === 'e';
    return `Encontrá ${
      isPlural ? (isMasculine ? 'los mejores' : 'las mejores') : ''
    }${!isPlural ? (isMasculine ? 'el mejor' : 'la mejor') : ''} ${option} de ${
      gender || landing
    } en Argentina ¡Comprá hasta 6 cuotas sin interés! ${
      initialReduxState.products?.pagination.current > 1
        ? ` | Página ${initialReduxState.products?.pagination.current}`
        : ''
    }`;
  };
  const slugsTitle = () => {
    if (!kind && !business && !brand && !sport) {
      return landing || keywords
        ? landingOrKeywordTitle(!!gender)
        : allProductsTitle();
    }
    return `${
      keywords
        ? `Comprá lo mejor de ${capitalize(keywords)} ${
            isDifferentToKeyword(brand) || isDifferentToKeyword(sport)
              ? 'en'
              : ''
          } `
        : ''
    } ${landing ? ` lo mejor de ${capitalize(landing, '-')} en ` : ''}${
      // eslint-disable-next-line no-nested-ternary
      isDifferentToKeyword(kind) ? `Comprá ${kind}` : ''
    } ${isDifferentToKeyword(business) ? business : ''} ${
      isDifferentToKeyword(brand) ? brand : ''
    }${isDifferentToKeyword(sport) ? ` de ${sport}` : ''}${
      value && value.trim() ? ` de ${value}` : ''
    }`;
  };

  return {
    title: `${slugsTitle()}${
      initialReduxState.products?.pagination.current > 1
        ? ` | Página ${initialReduxState.products?.pagination.current}`
        : ''
    }`,
    description: renderDescription()
  };
};

export default renderGalleryInfo;
