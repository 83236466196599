import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './container.module.scss';

const Container = ({ children, className, style, margin }) => {
  const styleContainer = cn({
    [styles.container]: true,
    [className]: !!className
  });
  return (
    <div
      className={styleContainer}
      style={{ ...style, ...(margin ? { margin } : {}) }}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  margin: PropTypes.string,
  style: PropTypes.object
};

Container.defaultProps = {
  className: '',
  margin: '',
  style: {}
};

export default Container;
