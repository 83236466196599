import PropTypes from 'prop-types';
import { Text } from '@/components/atoms';
import styles from './cyberBenefits.module.scss';

const CyberBenefits = ({ icon, title, text }) => {
  return (
    <div className={styles.container}>
      <div className={styles.badge}>{icon}</div>
      <Text className={styles.title}>{title}</Text>
      <Text className={styles.text}>{text}</Text>
    </div>
  );
};
CyberBenefits.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default CyberBenefits;
