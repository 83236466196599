export default function capitalize(s, separator = ' ') {
  // Palabras que no deben estar en uppercase
  const exeptions = ['adidas'];
  const toUpperCase = (string) =>
    !exeptions.includes(string)
      ? string[0].toUpperCase() + string.substring(1)
      : string;
  return s
    ?.split(separator)
    .map((x) => (x ? toUpperCase(x) : ''))
    .join(' ');
}
