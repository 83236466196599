import dynamic from 'next/dynamic';

export const About = dynamic(() => import('./About'));
export const LoginWithCodePage = dynamic(() => import('./LoginWithCode'));
export const Accounts = dynamic(() => import('./Accounts'));
export * from './Checkout';
export * from './Help';
export const Home = dynamic(() => import('./Home'));
export const Partners = dynamic(() => import('./Partners'));
export const Values = dynamic(() => import('./Values'));
export const ProductsGalleryPage = dynamic(() => import('./ProductsGallery'));
export const ProductSheet = dynamic(() => import('./ProductSheet'));
