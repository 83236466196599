import dynamic from 'next/dynamic';

export const BillingInfoForm = dynamic(() => import('./BillingInfoForm'));
export const ConfirmAddress = dynamic(() => import('./ConfirmAddress'));
export const ConfirmData = dynamic(() => import('./ConfirmData'));
export const LoginCheckout = dynamic(() => import('./LoginCheckout'));
export const MailLogin = dynamic(() => import('./MailLogin'));
export const PaymentForm = dynamic(() => import('./PaymentForm'));
export const PersonalInfo = dynamic(() => import('./PersonalInfo'));
export const Review = dynamic(() => import('./Review'));
export const ShippingInfoForm = dynamic(() => import('./ShippingInfoForm'));
export const ShippingMethod = dynamic(() => import('./ShippingMethod'));

export const MobilePaymentForm = dynamic(() =>
  import('./PaymentForm/MobilePaymentForm')
);
export const DesktopPaymentForm = dynamic(() =>
  import('./PaymentForm/DesktopPaymentForm')
);
