import PropTypes from 'prop-types';
import { Image, Text } from '@/components/atoms';
import styles from './cyberLink.module.scss';

const CyberLink = ({ banner, title, text, align }) => {
  return (
    <div className={styles.container}>
      {align === 'center' && (
        <Text
          weight="bolder"
          textSize="xxl"
          textColor="var(--color-primary)"
          className={styles.title}
        >
          {title}
        </Text>
      )}
      {align === 'left' && <div className={styles.gap} />}
      {align !== 'left' && (
        <div className={styles.img}>
          <Image
            width="100%"
            height="100%"
            src={banner}
            alt="banner"
          />
        </div>
      )}
      <div className={styles.content}>
        {align !== 'center' && (
          <Text
            weight="bolder"
            textSize="xxl"
            textColor="var(--color-primary)"
            className={styles.title}
          >
            {title}
          </Text>
        )}
        <Text className={styles.text}>{text}</Text>
      </div>
      {align === 'left' && (
        <div className={styles.img}>
          <Image
            width="100%"
            height="100%"
            src={banner}
            alt="banner"
          />
        </div>
      )}
      {align === 'right' && <div className={styles.gap} />}
    </div>
  );
};
CyberLink.propTypes = {
  banner: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['center', 'left', 'right']).isRequired
};

export default CyberLink;
