import dynamic from 'next/dynamic';

export const StoreBranchItem = dynamic(() =>
  import('./Help/StoreBranches/StoreBranchList/StoreBranchItem')
);
export const StoreBranchList = dynamic(() =>
  import('./Help/StoreBranches/StoreBranchList')
);
export const StoreBranches = dynamic(() => import('./Help/StoreBranches'));
export const Help = dynamic(() => import('./Help'));
